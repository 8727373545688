html, body {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
}

.content-container {
    padding: 0rem !important;
}

body {
  overflow-y: auto;
}

.full-width-container {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.landing-page-wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: #1F2937;
}

.landing-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
}

.section-content {
  width: 100%;
  max-width: 1280px;
  padding: 0 2rem;
  box-sizing: border-box;
}

.hero-section {
  position: relative;
  background-color: #4338ca; /* Indigo-700 */
  overflow: hidden;
  min-height: 70vh; /* This will make the hero section 70% as tall as the viewport */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}

.hero-section .logo {
  width: 50px; /* Reduced from 60px to 50px */
  height: auto;
  margin-right: 1rem;
}

.features-section {
  background-color: #1F2937;
}

.encryption-analytics-section {
  background-color: #111827;
}

.advantages-section {
  background-color: #1F2937;
}

.cta-section {
  background-color: #4F46E5;
}

.footer-section {
  background-color: #1F2937;
}

.grid {
  display: grid;
  gap: 2rem;
}

/* Add these styles to ensure full-width backgrounds */
.bg-gray-800 {
  background-color: #1F2937;
}

.bg-indigo-600 {
  background-color: #4F46E5;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .landing-section {
    padding: 3rem 0;
  }

  .section-content {
    padding: 0 1rem;
  }
}

/* Add this new style for the logo container */
.hero-logo-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.particle-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.8; /* Increased from 0.6 to 0.8 */
}

.hero-section .section-content {
  position: relative;
  z-index: 1;
  padding: 2rem;
  border-radius: 10px;
  max-width: 1280px; /* Ensure content doesn't stretch too wide on large screens */
  width: 100%;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%);
  pointer-events: none;
}

.hero-logo-container::after {
  content: '';
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 150px;
  background: radial-gradient(circle, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 70%);
  z-index: -1;
}

/* Remove the .pattern-dots class as it's no longer needed */

/* Add a subtle animation to the hero points */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(67, 56, 202, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(67, 56, 202, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(67, 56, 202, 0);
  }
}

.hero-section .grid > div {
  animation: pulse 2s infinite;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.hero-section .grid > div:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Adjust the grid layout for better fit in half-height hero */
.hero-section .grid {
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero-section {
    min-height: 90vh; /* Increase height on smaller screens for better content fit */
  }

  .hero-section .grid {
    grid-template-columns: 1fr; /* Stack items vertically on smaller screens */
  }
}

/* Add this new animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, 
    rgba(79, 70, 229, 0.7) 0%, 
    rgba(67, 56, 202, 0.7) 33%, 
    rgba(99, 102, 241, 0.7) 66%, 
    rgba(79, 70, 229, 0.7) 100%
  );
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
  pointer-events: none;
}

/* ... rest of the styles remain unchanged ... */