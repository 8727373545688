@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  background: linear-gradient(to bottom right, #1f2937, #111827);
  color: #e5e7eb;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-container {
  flex-grow: 1;
  padding: 1rem;
}

html, body, #root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

@media (min-width: 640px) {
  .content-container {
    padding: 2rem;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(31, 41, 55, 0.8); /* Slightly transparent dark background */
}

/* Add more global styles as needed */

.comment-section {
  margin-top: 2rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

.comment-section .MuiListItem-root {
  padding-left: 0;
  padding-right: 0;
}

.comment-section .MuiDivider-root {
  margin: 0.5rem 0;
}

.comment-section .MuiTextField-root {
  margin-bottom: 1rem;
}

.comment-section .MuiButton-root {
  margin-top: 0.5rem;
}